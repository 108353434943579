export default [
  {
    id: 'Auckland',
    value: 'Auckland',
    name: 'Auckland',
  },
  {
    id: 'Christchurch',
    value: 'Christchurch',
    name: 'Christchurch',
  },
  {
    id: 'Queenstown',
    value: 'Queenstown',
    name: 'Queenstown',
  },
  {
    id: 'Taupo',
    value: 'Taupo',
    name: 'Taupo',
  },
  {
    id: 'Paihia',
    value: 'Paihia',
    name: 'Paihia',
  },
];
