export default [
  {
    id: 'Facebook',
    value: 'Facebook',
    name: 'Facebook',
  },
  {
    id: 'newzealand.com',
    value: 'newzealand.com',
    name: 'newzealand.com',
  },
  {
    id: 'Google',
    value: 'Google',
    name: 'Google',
  },
  {
    id: 'Travel Agency',
    value: 'Travel Agency',
    name: 'Travel Agency',
  },
  {
    id: 'Friend or Word of Mouth',
    value: 'Friend or Word of Mouth',
    name: 'Friend or Word of Mouth',
  },
  {
    id: 'Travel Blogger/Writer',
    value: 'Travel Blogger/Writer',
    name: 'Travel Blogger/Writer',
  },
  {
    id: 'Magazine',
    value: 'Magazine',
    name: 'Magazine',
  },
  {
    id: 'Other Website',
    value: 'Other Website',
    name: 'Other Website',
  },
  {
    id: 'Brochure/flyer',
    value: 'Brochure/flyer',
    name: 'Brochure/flyer',
  },
  {
    id: 'Travel Expo',
    value: 'Travel Expo',
    name: 'Travel Expo',
  },
  {
    id: 'Haka Lodge',
    value: 'Haka Lodge',
    name: 'Haka Lodge',
  },
  {
    id: 'nzsnowtours.com',
    value: 'nzsnowtours.com',
    name: 'nzsnowtours.com',
  },
];
