export default [
  {
    id: 'Australia New Zealand Snowboarding Magazine',
    value: 'Australia New Zealand Snowboarding Magazine',
    name: 'Australia New Zealand Snowboarding Magazine',
  },
  {
    id: 'Revolutions MTB',
    value: 'Revolutions MTB',
    name: 'Revolutions MTB',
  },
  {
    id: 'AMB',
    value: 'AMB',
    name: 'AMB',
  },
  {
    id: 'Outer Edge Magazine',
    value: 'Outer Edge Magazine',
    name: 'Outer Edge Magazine',
  },
  {
    id: 'Go Travel New Zealand',
    value: 'Go Travel New Zealand',
    name: 'Go Travel New Zealand',
  },
  {
    id: 'Chill',
    value: 'Chill',
    name: 'Chill',
  },
];
