export default [
  {
    id: 'YHA.co.nz',
    value: 'YHA.co.nz',
    name: 'YHA.co.nz',
  },
  {
    id: 'tourism.net.nz',
    value: 'tourism.net.nz',
    name: 'tourism.net.nz',
  },
  {
    id: 'backpackerboard.co.nz',
    value: 'backpackerboard.co.nz',
    name: 'backpackerboard.co.nz',
  },
  {
    id: 'ski.com.au',
    value: 'ski.com.au',
    name: 'ski.com.au',
  },
  {
    id: 'snow.co.nz',
    value: 'snow.co.nz',
    name: 'snow.co.nz',
  },
  {
    id: 'Tour Radar',
    value: 'Tour Radar',
    name: 'Tour Radar',
  },
  {
    id: 'Powderhounds',
    value: 'Powderhounds',
    name: 'Powderhounds',
  },
];
