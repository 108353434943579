import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { navigate, Link } from 'gatsby';

import { BookingLayout } from 'containers';
import { BookingHeader, TravellerDetailsFieldset } from 'layouts';
import {
  BookingSummary,
  BookingToolbar,
  FormInput,
  FormSelect,
  withFormGroup,
} from 'components';
import { PAGES } from 'config';
import { mapStateToProps } from 'utilities';
import { UPDATE_BOOKING } from 'actions/booking';

import HowHeardGroups from 'config/how-heards';
import HakaLodge from 'config/haka-lodge';
import Magazine from 'config/magazine';
import OtherWebsite from 'config/other-website';
import TravelBlogger from 'config/travel-blogger';
import TravelExpo from 'config/travel-expo';

const Input = withFormGroup(FormInput);
const Select = withFormGroup(FormSelect);

class BookingsDetailsPage extends PureComponent {
  /**
   * Merge form data with travellers data
   * @param travellers
   * @param formData
   */
  static mergeTravellerData(travellers, formData) {
    return travellers
      .map((traveller, index) => {
        const DAY = formData.getAll('TravellerDOBDay')[index];
        const MONTH = formData.getAll('TravellerDOBMonth')[index];
        const YEAR = formData.getAll('TravellerDOBYear')[index];
        return {
          ...traveller,
          // overwrite with form values
          first_name: formData.getAll('TravellerFirstName')[index],
          last_name: formData.getAll('TravellerLastName')[index],
          date_of_birth: `${YEAR}-${MONTH}-${DAY}`,
          nationality: formData.getAll('TravellerNationality')[index],
          title: formData.getAll('TravellerTitle')[index],
          current_location: formData.getAll('TravellerLocation')[index],
          allergies: formData.getAll('TravellerAllergies')[index],
        };
      });
  }

  constructor(props) {
    super(props);
    this.state = {
      formAction: 'save',
      saving: false,
      howHeardGroup: undefined,
      howHeardDetail: undefined,
      howHeardDetailsList: undefined,
    };
    this.handleSave = this.handleSave.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.howHeardGroupsChange = this.howHeardGroupsChange.bind(this);
    this.howHeardDetailChange = this.howHeardDetailChange.bind(this);
  }

  getDefaultEmail() {
    const { user, booking } = this.props;
    let emailAddress = '';
    if (user.session.is_guest) {
      emailAddress = booking.currentBooking.contact_email;
    } else {
      emailAddress = user.session.is_guest.email;
    }
    return emailAddress;
  }

  /**
   * Set form action to "save"
   */
  handleSave() {
    this.setState({
      formAction: 'save',
    });
    // let form submit...
  }

  /**
   * Set form action to "next"
   */
  handleNext() {
    this.setState({
      formAction: 'next',
    });
    // let form submit...
  }

  howHeardDetailChange(e) {
    this.setState({ howHeardDetail: e.HowHeardDetail });
  }

  howHeardGroupsChange(e) {
    this.setState({ howHeardGroup: e.HowHeardGroup });
    this.setState({ howHeardDetail: undefined });
    switch (e.HowHeardGroup) {
      case 'Travel Blogger/Writer':
        this.setState({
          howHeardDetailsList: TravelBlogger,
        });
        break;
      case 'Magazine':
        this.setState({
          howHeardDetailsList: Magazine,
        });
        break;
      case 'Other Website':
        this.setState({
          howHeardDetailsList: OtherWebsite,
        });
        break;
      case 'Travel Expo':
        this.setState({
          howHeardDetailsList: TravelExpo,
        });
        break;
      case 'Haka Lodge':
        this.setState({
          howHeardDetailsList: HakaLodge,
        });
        break;
      default:
        this.setState({
          howHeardDetailsList: undefined,
        });
    }
  }

  /**
   * Handle form submit
   */
  handleFormSubmit(e) {
    e.preventDefault();
    const { user, booking, dispatch } = this.props;
    const { formAction } = this.state;
    const FORM = e.currentTarget;
    const FORM_DATA = new FormData(FORM);
    if (!FORM.checkValidity()) return;

    this.setState({
      saving: true,
    }, async () => {
      const TRAVELLERS = BookingsDetailsPage.mergeTravellerData(booking.travellers, FORM_DATA);
      // 1. Update booking
      const UPDATE = await UPDATE_BOOKING(booking.currentBooking.id, user.session.token, user.session.email, {
        travellers: TRAVELLERS,
        agree_with_the_conditions: FORM_DATA.get('Terms') === 'on' || false,
        special_requests: FORM_DATA.get('SpecialRequests'),
        how_heard_group: FORM_DATA.get('HowHeardGroup'),
        how_heard: FORM_DATA.get('HowHeardDetail'),
        phone: FORM_DATA.get('ContactNumber'),
        contact_email: FORM_DATA.get('EmailAddress'),
      });

      dispatch({
        type: 'MESSAGES_ADD',
        payload: {
          id: 'update-booking',
          type: UPDATE.success ? 'success' : 'error',
          content: UPDATE.success ? 'Your booking has been updated' : 'Failed to update your booking.',
        },
      });

      setTimeout(() => {
        dispatch({
          type: 'MESSAGES_REMOVE',
          payload: 'update-booking',
        });
      }, 5000);

      if (!UPDATE.success) return;

      // 2. Redirect user if "next action"
      if (UPDATE.success && formAction === 'next') {
        navigate(`${PAGES.BOOKINGS_ADD_ONS.PATH}?bookingId=${booking.currentBooking.id}&tour=${booking.currentBooking.tour_slug}`);
      }

      this.setState({
        saving: false,
      });
    });
  }

  render() {
    const { booking, user } = this.props;
    const {
      saving,
      howHeardGroup,
      howHeardDetailsList,
      howHeardDetail,
    } = this.state;
    const { currentBooking, travellers } = booking;
    if (currentBooking) {
      if (howHeardGroup == null) {
        this.setState({ howHeardGroup: currentBooking.how_heard_group });
        switch (currentBooking.how_heard_group) {
          case 'Travel Blogger/Writer':
            this.setState({
              howHeardDetailsList: TravelBlogger,
            });
            break;
          case 'Magazine':
            this.setState({
              howHeardDetailsList: Magazine,
            });
            break;
          case 'Other Website':
            this.setState({
              howHeardDetailsList: OtherWebsite,
            });
            break;
          case 'Travel Expo':
            this.setState({
              howHeardDetailsList: TravelExpo,
            });
            break;
          case 'Haka Lodge':
            this.setState({
              howHeardDetailsList: HakaLodge,
            });
            break;
          default:
            this.setState({
              howHeardDetailsList: undefined,
            });
        }
      }
      if (howHeardDetail == null) {
        this.setState({ howHeardDetail: currentBooking.how_heard });
      }
    }
    if (HowHeardGroups) {
      let i;
      let j;
      let x;
      for (i = HowHeardGroups.length - 1; i > 0; i -= 1) {
        j = Math.floor(Math.random() * (i + 1));
        x = HowHeardGroups[i];
        HowHeardGroups[i] = HowHeardGroups[j];
        HowHeardGroups[j] = x;
      }
    }
    return (
      <BookingLayout page="bookings/traveller-details">
        <div className="l-two-col">
          <BookingHeader
            title="Your Details"
          />
          <div>
            <div className="u-card">
              {!currentBooking || !travellers
                ? <h4 className="c-heading c-heading--h4">Loading...</h4>
                : (
                  <form className="l-traveller-details-form" action="" onSubmit={this.handleFormSubmit}>
                    {
                    travellers.map((traveller, index) => (
                      <TravellerDetailsFieldset
                        key={traveller.id}
                        title={index === 0 ? 'Primary Traveller details' : null}
                        traveller={traveller}
                        travellerNumber={index + 1}
                        nationality={traveller.nationality}
                        firstName={traveller.first_name}
                        lastName={traveller.last_name}
                        travellerTitle={traveller.title}
                      />
                    ))
                  }
                    <div className="l-form-group l-form-group--checkbox">
                      <FormInput
                        type="checkbox"
                        id="Terms"
                        name="Terms"
                        groupModifier="l-form-group--checkbox"
                        modifier="c-form-control--checkbox"
                        defaultChecked={currentBooking.agree_with_the_conditions}
                        required
                      />
                      {/* eslint-disable-next-line */}
                    <label htmlFor="Terms" className="l-form-group__label">
                      I have read and agree to the booking&nbsp;
                      <a
                        href={PAGES.TERMS.PATH}
                        target="_blank"
                        rel="noopener noreferrer"
                      >Terms and Conditions
                      </a>
                      &nbsp;*
                    </label>
                    </div>
                    <Input
                      id="SpecialRequests"
                      name="SpecialRequests"
                      type="textarea"
                      label="Special Requests"
                      placeholder="Let us know if you have any special requests"
                      defaultValue={currentBooking.special_requests}
                    />
                    <Select
                      id="HowHeardGroup"
                      name="HowHeardGroup"
                      label="How did you hear about us ? &#42;"
                      onChange={this.howHeardGroupsChange}
                      value={howHeardGroup}
                      placeholder="Pick an option.."
                      options={HowHeardGroups}
                      required
                    />

                    {
                      (howHeardDetailsList) ? (
                        <Select
                          id="HowHeardDetail"
                          name="HowHeardDetail"
                          label="Please specify"
                          onChange={this.howHeardDetailChange}
                          value={howHeardDetail}
                          options={howHeardDetailsList}
                        />
                      ) : null}
                    <Input
                      id="ContactNumber"
                      name="ContactNumber"
                      label="Best Contact Number &#42;"
                      defaultValue={currentBooking.phone}
                      placeholder="Phone number (include international dialling code)"
                      maxLength={32}
                      minLength={5}
                      required
                    />
                    <Input
                      id="EmailAddress"
                      name="EmailAddress"
                      label="Best Email Address &#42;"
                      defaultValue={user.session.is_guest ? currentBooking.contact_email : user.session.email}
                      placeholder="Email Address"
                      minLength={5}
                      required
                    />
                    <BookingToolbar saving={saving}>
                      {
                        (currentBooking && currentBooking.agree_with_the_conditions)
                          ? (
                            <Link
                              to={`${PAGES.BOOKINGS_REVIEW.PATH}?bookingId=${currentBooking.id}&tour=${currentBooking.tour_slug}`}
                              className="c-button c-button--border u-hide-screen-large-up"
                            >
                              Summary
                            </Link>
                          ) : null
                      }
                      <button onClick={this.handleSave} type="submit" value="Save" className="c-button c-button--border">Save</button>
                      <button onClick={this.handleNext} type="submit" className="c-button c-button--primary c-button--wide">Next</button>
                    </BookingToolbar>
                  </form>
                )
              }
            </div>
          </div>
          <div>
            {currentBooking ? <BookingSummary page="bookings/traveller-details" /> : ''}
          </div>
        </div>
      </BookingLayout>
    );
  }
}

BookingsDetailsPage.propTypes = {
  user: PropTypes.object.isRequired,
  booking: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default connect(mapStateToProps)(BookingsDetailsPage);
