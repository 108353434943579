export default [
  {
    id: 'Little Grey Box',
    value: 'Little Grey Box',
    name: 'Little Grey Box',
  },
  {
    id: 'Stephen Schreck',
    value: 'Stephen Schreck',
    name: 'Stephen Schreck',
  },
  {
    id: 'Living in Another Language',
    value: 'Living in Another Language',
    name: 'Living in Another Language',
  },
  {
    id: 'Nadia v The World',
    value: 'Nadia v The World',
    name: 'Nadia v The World',
  },
  {
    id: 'Till the Money Runs out',
    value: 'Till the Money Runs out',
    name: 'Till the Money Runs out',
  },
  {
    id: 'Young Adventuress',
    value: 'Young Adventuress',
    name: 'Young Adventuress',
  },
  {
    id: 'Seek the World',
    value: 'Seek the World',
    name: 'Seek the World',
  },
];
